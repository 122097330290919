import { IContent } from 'src/common/content/types';
import { isEudoraSpc, stateUf } from 'src/common/helpers';

const eudora: IContent = {
  name: 'Eudora',
  platform: 'Guia',
  urlBase: 'https://guia.eudora.com.br',
  defaultState: 'PR',
  ogImage:
    'https://meucatalogodigitalresources.grupoboticario.digital/assets/images/EUD/avatar-default.png',
  optimizeId: 'OPT-KH6SFX2',
  CatalogUTMParams: {
    source: 'site_guia_eudora',
    medium: 'botao_acessar_catalogo',
  },
  header: {
    logo: {
      image:
        'https://gb-assets.grupoboticario.digital/eudora/logo/logo-white.svg',
      alt: 'Marca Eudora',
    },
  },
  footer: {
    social: [
      {
        name: 'facebook',
        icon: 'facebook',
        url: 'https://facebook.com/eudora',
      },
      {
        name: 'instagram',
        icon: 'instagram',
        url: 'https://instagram.com/eudora',
      },
      {
        name: 'youtube',
        icon: 'youtube',
        url: 'https://youtube.com/eudora',
      },
      {
        name: 'twitter',
        icon: 'twitter',
        url: 'https://twitter.com/eudora',
      },
    ],
    navigation: [
      {
        label: 'Quero ser um revendedor',
        url: 'https://cadastro.eudora.com.br/?utm_source=catalogo&utm_medium=footer',
      },
      {
        label: 'Loja on-line Eudora',
        url: 'https://www.eudora.com.br/?utm_source=catalogo&utm_medium=footer',
      },
    ],
    logo: {
      image:
        'https://gb-assets.grupoboticario.digital/eudora/logo/logo-black.svg',
      alt: 'Marca Eudora',
    },
  },
  states: [
    'AL',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MG',
    'MS',
    'MT',
    'PA',
    'PB',
    'PE',
    'PI',
    'PR',
    'RJ',
    'RN',
    'RS',
    'SC',
    'SE',
    'SPC',
    'SPI',
  ],
  getUFByStateSlug: (slug: string, city?: string) => {
    if (slug !== 'sao-paulo') {
      return stateUf[slug];
    }

    if (isEudoraSpc(city as string)) {
      return stateUf['sao-paulo-capital'];
    }

    return stateUf['sao-paulo-interior'];
  },
  summary: {
    title: 'Você vai encontrar nesse guia:',
    categories: [
      {
        image: 'perfumaria',
        label: 'Novidades em perfumaria',
      },
      {
        image: 'maquiagem',
        label: 'Cuidados e maquiagem',
      },
      {
        image: 'cabelo',
        label: 'Produtos para cabelo',
      },
    ],
  },
  products: {
    showPrice: true,
    findReseller: {
      button: {
        ariaLabel: 'Encontre uma ou um representante',
        label: 'Encontre um(a) representante',
        url: 'https://ondecomprar.eudora.com.br/chat?utm_source=revista-digital&utm_medium=detalhes-do-produto&utm_campaign=',
      },
    },
  },
};

export default eudora;
