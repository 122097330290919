import { styled } from '@grupoboticario/flora-react';

const Select = styled('select', {
  appearance: 'none',
  backgroundColor: '$light-light',
  backgroundImage: 'url(/assets/images/icon-expand.png)',
  backgroundPositionX: 'calc(100% - 8px)',
  backgroundPositionY: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '16px',
  borderColor: '$medium-medium',
  borderRadius: '$small',
  borderStyle: 'solid',
  borderWidth: '$hairline',
  color: '$dark-dark',
  display: 'flex',
  flexWrap: 'wrap',
  padding: '$0 $3',
});

export default Select;
