import { NextRouter } from 'next/router';

export default {
  productsList: {
    openedSectionTitle: 'Lista de produtos da página',
    closedSectionTitle: 'Toque para ver detalhes dos produtos',
    productDetailsTitle: 'Detalhes do produto',
  },
  errorHandling: {
    notFound: {
      title: 'Ops, parece que essa página não existe ou foi removida.',
      description:
        'Confira se o endereço está correto, ou vá para a página inicial para continuar navegando.',
      button: {
        text: 'Ir para a página inicial',
        action: (router: NextRouter) => {
          router.push('/');
        },
      },
    },
    productNotFound: {
      title: 'Ops, estamos com um problema!',
      description:
        'Ih, não conseguimos encontrar esse produto. Mas não se preocupe, temos outras opções que vão ganhar seu coração. Experimente fazer uma busca!',
      button: {
        text: 'Ok Entendi',
        action: (router: NextRouter) => {
          router.push('/busca');
        },
      },
    },
    emptyProductsList: {
      title: 'Essa página não contém nenhum produto listado!',
      description:
        'Navegue pelo restante do catálogo para ver os produtos aqui.',
      button: {
        text: '',
        action: (_router: NextRouter) => {},
      },
    },
    default: {
      title: 'Puxa, um erro aconteceu. Sinto muito!',
      description:
        'Por favor, tente recarregar a página. Caso o erro continue acontecendo, aguarde uns minutinhos e tente novamente!',
      button: {
        text: 'Recarregar página',
        action: (router: NextRouter) => {
          router.reload();
        },
      },
    },
  },
};
