import React, { ReactNode } from 'react';
import slugify from 'react-slugify';

import { styled, Text } from '@grupoboticario/flora-react';

export interface IListMenuItem {
  label: ReactNode;
  href?: string;
  target?: string;
  download?: boolean;
  items?: IListMenuItem[];
  onClick?: Function;
  testId?: string;
}

export interface IListMenu {
  items: IListMenuItem[];
  onChangeMenu?: (items: IListMenuItem[]) => void;
}

const Styled = {
  Wrapper: styled('div', {
    background: '$light-dark-1',
    borderRadius: '$small',
    boxShadow: '$downSoft',
    padding: '$2 $0',

    ul: {
      li: {
        position: 'relative',

        '&:before': {
          background: '$medium-light-2',
          content: ' ',
          display: 'block',
          height: '1px',
          left: '$5',
          position: 'absolute',
          top: '0',
          width: 'calc(100% - $5 - $5)',
        },

        '&:first-child:before': {
          display: 'none',
        },

        ul: {
          display: 'none',
        },
      },
    },
  }),

  Item: styled('button', {
    background: 'transparent',
    border: 'none',
    display: 'block',
    padding: '$4 $5',
    position: 'relative',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    width: '100%',

    span: {
      position: 'relative',
      zIndex: '2',
    },

    '&:before': {
      background: '$medium-medium',
      content: ' ',
      display: 'block',
      height: 'calc(100% + 2px)',
      left: '0',
      opacity: '0',
      position: 'absolute',
      top: '-1px',
      transition: 'all 0.15s ease-in-out',
      width: '0',
    },

    '&:hover': {
      zIndex: '2',
      '&:before': {
        opacity: '1',
        width: '100%',
      },
    },
  }),
};

const ItemsMenu: React.FC<{
  item: IListMenuItem;
  onChangeMenu?: (items: IListMenuItem[]) => void;
}> = ({ item, onChangeMenu }) => {
  const settingsLink = {
    as: 'a',
    href: item.href,
    target: item.target,
    download: item.download,
  };

  const settingsButton = {
    as: 'button',
  };

  const settings = item.href ? settingsLink : settingsButton;

  const handleClick = () => {
    if (!!item.items?.length && onChangeMenu) {
      onChangeMenu(item.items);
    } else if (!!item.onClick) {
      item.onClick();
    }
  };

  return (
    <Styled.Item
      {...settings}
      onClick={handleClick}
      data-testid={`item-${item.testId || slugify(item.label)}`}
    >
      <Text size="bodyLargeStandard" color="$dark-light">
        {item.label}
      </Text>
    </Styled.Item>
  );
};

export const ListMenu: React.FC<IListMenu> = ({ items, onChangeMenu }) => {
  return (
    <Styled.Wrapper>
      <ul>
        {items.map((item) => {
          return (
            <li key={slugify(item.label)}>
              <ItemsMenu item={item} onChangeMenu={onChangeMenu} />
            </li>
          );
        })}
      </ul>
    </Styled.Wrapper>
  );
};
