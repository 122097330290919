import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

import { Input, Button } from '@grupoboticario/flora-react';

import SearchIcon from '@material-ui/icons/Search';

import Styled from './SearchFormStyle';

export interface ISearchForm {
  onSubmit: (data: { term: string | string[] }) => void;
}

const SearchForm: React.FC<ISearchForm> = (props) => {
  const router = useRouter();

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      term: router.query.term,
    },
  });

  useEffect(() => {
    setValue('term', router.query.term);
  }, [router.query.term]);

  return (
    <Styled.Wrapper>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Input
          autoComplete="off"
          id="term"
          data-testid="term"
          labelText="Busque por nome ou código"
          optionalText=""
          {...register('term')}
        />
        <Styled.Button>
          <Button
            data-testid="submit-button"
            disabled={!watch('term')}
            type="submit"
            variant="alt"
            aria-label="Buscar"
          >
            <SearchIcon />
          </Button>
        </Styled.Button>
      </form>
    </Styled.Wrapper>
  );
};

export default SearchForm;
