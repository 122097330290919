import { styled } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('header', {
    alignItems: 'center',
    background: '$brand-1',
    display: 'flex',
    height: '60px',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',

    variants: {
      fixed: {
        true: {
          position: 'fixed',
          top: 0,
          zIndex: 10,
        },
      },
    },
  }),

  WrapperRow: styled('div', {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    height: '60px',
    justifyContent: 'space-between',
    width: '100%',
  }),

  Logo: styled('div', {
    display: 'flex',
    alignItems: 'center',

    img: {
      maxHeight: '28px',
      width: 'auto',
    },

    h1: {
      textAlign: 'center',
      marginLeft: '$2',
      '&:before': {
        content: '• ',
      },
    },
  }),

  Options: styled('div', {
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  }),

  MenuOptions: styled('div', {
    marginLeft: '$3',

    '@tablet': {
      marginLeft: '$1',
    },
  }),
};
