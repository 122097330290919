import React from 'react';

import Maquiagem from './maquiagem.svg';
import Perfumaria from './perfumaria.svg';
import Promocoes from './promocoes.svg';
import Cabelo from './cabelo.svg';
import Corpo from './corpo.svg';
import Accessibility from './accessibility.svg';
import Font from './font.svg';
import IncreaseFont from './increaseFont.svg';
import DecreaseFont from './decreaseFont.svg';
import Contrast from './contrast.svg';

const icons = [
  { name: 'maquiagem', icon: <Maquiagem /> },
  { name: 'perfumaria', icon: <Perfumaria /> },
  { name: 'promocoes', icon: <Promocoes /> },
  { name: 'cabelo', icon: <Cabelo /> },
  { name: 'corpo', icon: <Corpo /> },
  { name: 'accessibility', icon: <Accessibility /> },
  { name: 'font', icon: <Font /> },
  { name: 'increaseFont', icon: <IncreaseFont /> },
  { name: 'decreaseFont', icon: <DecreaseFont /> },
  { name: 'contrast', icon: <Contrast /> },
];

const RevistaIcons: React.FC<{
  name: string;
}> = ({ name }): JSX.Element => {
  const iconFind = icons.find((item) => item.name === name);
  return iconFind?.icon || <></>;
};

export default RevistaIcons;
