import {
  CatalogActionTypes,
  IGoToNextPageAction,
  IGoToPreviousPageAction,
  IGoToPageAction,
  IZoomInAction,
  IZoomOutAction,
  IIdentifyDeviceTypeAction,
} from './types';

export const goToNextPage = (): IGoToNextPageAction => ({
  type: CatalogActionTypes.GO_TO_NEXT_PAGE,
});

export const goToPreviousPage = (): IGoToPreviousPageAction => ({
  type: CatalogActionTypes.GO_TO_PREVIOUS_PAGE,
});

export const goToPage = (
  payload: IGoToPageAction['payload'],
): IGoToPageAction => ({
  type: CatalogActionTypes.GO_TO_PAGE,
  payload,
});

export const zoomIn = (): IZoomInAction => ({
  type: CatalogActionTypes.ZOOM_IN,
});

export const zoomOut = (): IZoomOutAction => ({
  type: CatalogActionTypes.ZOOM_OUT,
});

export const identifyDeviceType = (
  payload: IIdentifyDeviceTypeAction['payload'],
): IIdentifyDeviceTypeAction => ({
  type: CatalogActionTypes.IDENTIFY_DEVICE_TYPE,
  payload,
});
