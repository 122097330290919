import { styled } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('footer', {
    background: '$brand-1',
  }),

  WrapperRow: styled('div', {
    padding: '$5 $0',
    width: '100%',

    '@tablet': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: '135px',
      padding: '$5 $0',
    },
  }),

  Menu: styled('dl', {
    borderTop: '1px solid $light-light',
    padding: '$4 $0',

    '@tablet': {
      border: 'none',
      padding: '$0',
    },

    variants: {
      noBorder: {
        true: {
          borderTop: 'none',
        },
      },
    },
  }),

  Navigation: styled('ul', {
    li: {
      display: 'block',
      margin: '$0 $0 $1',
      listStyle: 'none',

      a: {
        color: '$light-light',
        textDecoration: 'none',

        '&:hover': {
          color: '$actionNavigationAltHighlight',
        },
      },
    },
  }),

  Title: styled('dt', {
    color: '$light-light',
    margin: '$0 $0 $4',
    textTransform: 'uppercase',
  }),

  SocialMedia: styled('ul', {
    li: {
      display: 'inline-block',
      listStyle: 'none',
      margin: '$0 $5 $0 $0',

      a: {
        color: '$light-light',

        '&:hover': {
          color: '$actionNavigationAltHighlight',
        },
      },

      '@tablet': {
        marginRight: '$3',
      },
    },
  }),
};
