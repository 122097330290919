interface localStorageServiceInterface {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => boolean;
}

const localStorageService: localStorageServiceInterface = {
  getItem: (key) => {
    try {
      return localStorage.getItem(key);
    } catch {
      return null;
    }
  },

  setItem: (key, value) => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch {
      return false;
    }
  },
};

export default localStorageService;
