import { IPage } from 'src/common/types/CatalogType';
import { ICatalogState } from './types';

const FIRST_PAGE = 1;

const isEven = (num: number) => num % 2 === 0;

export const getDesktopPagesNumber = (page: number) => {
  return isEven(page) ? [page, page + 1] : [page - 1, page];
};

export const isPageValid = (page: number, numberOfPages: number): boolean =>
  page <= numberOfPages && page >= FIRST_PAGE;

export const getValidPagePosition = (
  currentPage: number,
  newPage: number,
  numberOfPages: number,
): number => (isPageValid(newPage, numberOfPages) ? newPage : currentPage);

export const getNextPagePosition = (
  currentPosition: number,
  isDesktop = false,
) =>
  isDesktop
    ? getDesktopPagesNumber(currentPosition + 2)[0]
    : currentPosition + 1;

export const getPreviousPagePosition = (
  currentPosition: number,
  isDesktop = false,
) =>
  isDesktop && currentPosition - 2 !== 0
    ? getDesktopPagesNumber(currentPosition - 2)[0]
    : currentPosition - 1;

export const getPagesData = (pages: IPage[], pagesNumber: number[]) =>
  pages.filter((page) => pagesNumber.includes(page.number));

export const getExhibitionPagesNumber = (
  currentPage: number,
  isDesktop = false,
) => {
  if (!isDesktop) {
    return {
      currentPagesNumber: [currentPage],
      nextPagesNumber: [currentPage + 1, currentPage + 2],
      previousPagesNumber: [currentPage - 2, currentPage - 1],
    };
  } else {
    return {
      currentPagesNumber: getDesktopPagesNumber(currentPage),
      nextPagesNumber: getDesktopPagesNumber(currentPage + 2),
      previousPagesNumber: getDesktopPagesNumber(currentPage - 2),
    };
  }
};

export const getExhibitionData = (
  page: number,
  pages: IPage[],
  isDesktop = false,
): ICatalogState['exhibition'] => {
  const { currentPagesNumber, nextPagesNumber, previousPagesNumber } =
    getExhibitionPagesNumber(page, isDesktop);

  return {
    currentPagesData: getPagesData(pages, currentPagesNumber),
    nextPagesData: getPagesData(pages, nextPagesNumber),
    previousPagesData: getPagesData(pages, previousPagesNumber),
  };
};
