import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { Container } from 'src/styles';
import { styled, Heading, Text, Button } from '@grupoboticario/flora-react';
import IlustraBoticario from 'src/assets/images/ilustra-404-boticario.svg';
import IlustraEudora from 'src/assets/images/ilustra-404-eudora.svg';
import content from 'src/common/content';
import { ErrorTypes } from 'src/common/types/ErrorTypes';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  padding: '$20 0',

  svg: {
    fontSize: '$4xl',
    color: '$alert-standard',
  },
});

const Image = styled('div', {
  marginBottom: '$7',
  svg: {
    height: '136px',
    width: 'auto',

    '@tablet': {
      height: '176px',
    },
  },
});

const ilustra = {
  boticario: <IlustraBoticario aria-hidden />,
  eudora: <IlustraEudora aria-hidden />,
};

interface ErrorFeedbackProps {
  type: ErrorTypes;
}

export const ErrorFeedback: FC<ErrorFeedbackProps> = ({
  type = ErrorTypes.default,
}) => {
  const router = useRouter();
  const handleAction = () =>
    !!content.errorHandling[type].button.text &&
    content.errorHandling[type].button.action(router);

  return (
    <Container>
      <Wrapper>
        <Image aria-hidden="true" data-testid="error-image">
          {ilustra[process.env.BRAND_IDENTIFIER as 'boticario' | 'eudora']}
        </Image>
        <Heading level={6} css={{ margin: '0 auto $4' }}>
          {content.errorHandling[type].title}
        </Heading>
        <Text
          size="bodyLargeStandard"
          align="center"
          css={{ margin: '0 auto $6' }}
        >
          {content.errorHandling[type].description}
        </Text>
        {!!content.errorHandling[type].button.text && (
          <Button
            data-testid="ErrorFeedback-actionButton"
            size="small"
            onClick={handleAction}
            variant="ghost"
          >
            {content.errorHandling[type].button.text}
          </Button>
        )}
      </Wrapper>
    </Container>
  );
};
