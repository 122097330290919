import { useEffect, useState } from 'react';

const useScrollY = () => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    if (window) {
      const handleScroll = () => setScroll(window.scrollY);

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return scroll;
};

export default useScrollY;
