interface IBrandFonts {
  [key: string]: string[];
}

const brandFonts: IBrandFonts = {
  eudora: [
    'https://gb-assets.grupoboticario.digital/eudora/font/myriad-pro/myriad-pro.css',
    'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap',
  ],
  boticario: [
    'https://gb-assets.grupoboticario.digital/boticario/font/domaine-sans-text-regular/domainesanstext.css',
    'https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap',
  ],
  boticario_reseller: [
    'https://gb-assets.grupoboticario.digital/boticario/font/domaine-sans-text-regular/domainesanstext.css',
    'https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap',
  ],
};

export default brandFonts[process.env.BRAND_IDENTIFIER.toString()];
