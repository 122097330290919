import React from 'react';
import slugify from 'react-slugify';

import { Text } from '@grupoboticario/flora-react';

import { YouTube, Facebook, Twitter, Instagram } from '@material-ui/icons';

import content from 'src/common/content';

import Styled from './FooterStyle';
import { Container } from 'src/styles';

export default function Footer(): JSX.Element {
  const testId = 'footer';

  const socialIcons = (
    name: 'youtube' | 'facebook' | 'twitter' | 'instagram',
  ): JSX.Element => {
    const allIcons = {
      youtube: <YouTube aria-hidden />,
      facebook: <Facebook aria-hidden />,
      twitter: <Twitter aria-hidden />,
      instagram: <Instagram aria-hidden />,
    };

    return allIcons[name] || <></>;
  };

  return (
    <Styled.Wrapper role="contentinfo">
      <Container>
        <Styled.WrapperRow>
          <Styled.Menu noBorder>
            <Styled.Title>
              <Text size="action">Redes Sociais</Text>
            </Styled.Title>
            <dd>
              <Styled.SocialMedia role="list" aria-label="Redes Socias">
                {content.footer.social.map((item, index) => (
                  <li key={`nav-social-${index}`}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer noopener"
                      data-gtm-event-category={`${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`}
                      data-gtm-event-action="clique:footer:redes-sociais"
                      data-gtm-event-label={item.icon}
                      aria-label={`${item.icon} ${content.name}`}
                    >
                      {socialIcons(
                        item.icon as
                          | 'youtube'
                          | 'facebook'
                          | 'twitter'
                          | 'instagram',
                      )}
                    </a>
                  </li>
                ))}
              </Styled.SocialMedia>
            </dd>
          </Styled.Menu>

          <Styled.Menu>
            <Styled.Title>
              <Text size="action">Institucional</Text>
            </Styled.Title>
            <dd>
              <Styled.Navigation role="list" aria-label="institucional">
                {content.footer.navigation.map((item, index) => (
                  <Text
                    as="li"
                    size="auxiliarSmall"
                    key={`nav-footer-${index}`}
                  >
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer noopener"
                      data-testid={`${testId}-nav-footer-${index}`}
                      data-gtm-event-category={`${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`}
                      data-gtm-event-action="clique:footer:institucional"
                      data-gtm-event-label={slugify(item.label)}
                      aria-label={item.label}
                    >
                      {item.label}
                    </a>
                  </Text>
                ))}
              </Styled.Navigation>
            </dd>
          </Styled.Menu>
          <Styled.Menu>
            <Styled.Title>
              <Text size="action">Links Úteis</Text>
            </Styled.Title>
            <dd>
              <Styled.Navigation role="list" aria-label="Links Úteis">
                <Text as="li" size="auxiliarSmall">
                  <a
                    href="https://privacidade.grupoboticario.com.br"
                    target="_blank"
                    rel="noreferrer noopener"
                    data-gtm-event-category={`${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`}
                    data-gtm-event-action="clique:footer:links-uteis"
                    data-gtm-event-label="politica-de-privacidade"
                    aria-label="Política de privacidade"
                  >
                    Política de privacidade
                  </a>
                </Text>
                <Text as="li" size="auxiliarSmall">
                  <a
                    href="https://privacidade.grupoboticario.com.br/proteja-se.html"
                    target="_blank"
                    rel="noreferrer noopener"
                    data-gtm-event-category={`${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`}
                    data-gtm-event-action="clique:footer:links-uteis"
                    data-gtm-event-label="proteja-se-contra-fraudes"
                    aria-label="Proteja-se contra fraudes"
                  >
                    Proteja-se contra fraudes
                  </a>
                </Text>
                <Text as="li" size="auxiliarSmall">
                  <a
                    href="https://meucatalogodigitalresources.grupoboticario.digital/assets/pdfs/termos-de-uso.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                    data-gtm-event-category={`${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`}
                    data-gtm-event-action="clique:footer:links-uteis"
                    data-gtm-event-label="termos-de-uso"
                    aria-label="Termos de Uso"
                  >
                    Termos de Uso
                  </a>
                </Text>
              </Styled.Navigation>
            </dd>
          </Styled.Menu>

          <Styled.Menu>
            <Styled.Title>
              <Text size="action">Navegação</Text>
            </Styled.Title>
            <dd>
              <Styled.Navigation role="list" aria-label="Navegação">
                <Text as="li" size="auxiliarSmall">
                  <a
                    className="ot-sdk-show-settings"
                    data-gtm-event-category={`${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`}
                    data-gtm-event-action="clique:footer:navegacao"
                    data-gtm-event-label="preferencia-de-cookies"
                    aria-label="Preferência de cookies"
                  >
                    Preferência de cookies
                  </a>
                </Text>
              </Styled.Navigation>
            </dd>
          </Styled.Menu>
        </Styled.WrapperRow>
      </Container>
    </Styled.Wrapper>
  );
}
