import React, { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorTypes } from 'src/common/types/ErrorTypes';
import { ErrorPage } from 'src/components/templates/ErrorPage';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  constructor(props: Props) {
    super(props);
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage type={ErrorTypes.default} />;
    }

    return this.props.children;
  }
}
