import React from 'react';
import {
  styled,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
  Text,
} from '@grupoboticario/flora-react';

import SharedButtons from '../SharedButtons';
import useWindowSize from 'src/common/hooks/useWindowSize';

interface IDrawerShared {
  url?: string;
  isOpen?: boolean;
  onOpenChange?: () => void;
}

const Styled = {
  Wrapper: styled('div', {
    padding: '$5',
  }),

  Options: styled('div', {
    paddingTop: '$5',
  }),
};

export const DrawerShared: React.FC<IDrawerShared> = ({
  children,
  url,
  isOpen,
  onOpenChange,
}) => {
  const { isDesktop } = useWindowSize();

  const settingsMobile = {
    side: 'bottom',
    css: {
      borderTopLeftRadius: '$medium',
      borderTopRightRadius: '$medium',
      left: 0,
      margin: '0 auto',
      maxWidth: '600px',
      right: 0,
    },
  };

  const settingsDesktop = {
    side: 'right',
    css: {
      padding: '$5 $3',
    },
  };

  const getSettingsDrawer = () =>
    isDesktop ? settingsDesktop : settingsMobile;

  return (
    <Drawer open={isOpen} onOpenChange={onOpenChange}>
      {!!children && <DrawerTrigger as="div">{children}</DrawerTrigger>}
      <DrawerContent {...(getSettingsDrawer() as any)}>
        <DrawerClose />
        <Styled.Wrapper>
          <Text size="bodyLargeStandard" color="$dark-light">
            Escolha como compartilhar:
          </Text>
          <Styled.Options>
            <SharedButtons url={url} />
          </Styled.Options>
        </Styled.Wrapper>
      </DrawerContent>
    </Drawer>
  );
};
