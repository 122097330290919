export const startDownloadFile = (link: string): void => {
  const element = document.createElement('a');
  element.href = link;
  element.id = 'tmp-download';
  element.target = '_blank';
  element.rel = 'nofollow noreferrer noopener';
  element.download = '';

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
