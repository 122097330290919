import React, { FC, useContext, useState } from 'react';
import { useRouter } from 'next/router';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import { styled } from '@grupoboticario/flora-react';

import sendGoogleAnalytics from 'src/services/googleAnalytics/sendGoogleAnalytics';

import { DropDownMenu } from 'src/components/atoms/DropDownMenu';
import { DrawerShared } from 'src/components/molecules/DrawerShared';

import { CatalogContext } from 'src/contexts/Catalog';

const Styled = {
  MoreButton: styled('div', {
    marginRight: '$3',
  }),

  MoreButtonItem: styled('button', {
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    color: '$actionNavigationStandardStatic',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    padding: '$0',
    width: 'auto',

    variants: {
      isDesktop: {
        true: {
          color: '$light-light',
        },
      },
    },
  }),

  Loading: styled('div', {
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.6)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 999,
  }),
};

export const CatalogMenu: FC = () => {
  const router = useRouter();

  const [state] = useContext(CatalogContext);
  const [isDrawerShare, setIsDrawerShare] = useState(false);
  const [urlShared, setUrlShared] = useState('');

  const analyticsEvent = (label: string) => {
    sendGoogleAnalytics.event({
      eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual`,
      eventAction: 'clique',
      eventLabel: `menu-3-pontos:${label}`,
    });
  };

  const openShareDrawer = (page: string) => {
    setUrlShared(page);
    setIsDrawerShare(true);
  };

  const closeDrawerShare = () => setIsDrawerShare(false);

  const sharePage = () => {
    analyticsEvent('compartilhar-pagina');
    openShareDrawer(
      `${state.catalogData.cycle.state.toLocaleLowerCase()}/visualizar/?page=${
        state.page
      }`,
    );
  };

  const shareCatalog = () => {
    analyticsEvent('compartilhar-catalogo');
    openShareDrawer(state.catalogData.cycle.state.toLocaleLowerCase());
  };

  const goToAcessibilidade = () => {
    router.push('/acessibilidade');
    analyticsEvent('acessibilidade');
  };

  const menuMobile = [
    {
      label: 'Menu acessibilidade',
      onClick: goToAcessibilidade,
    },
    {
      label: 'Compartilhar',
      items: [
        {
          label: 'Compartilhar essa página',
          onClick: () => sharePage(),
        },
        {
          label: 'Compartilhar todo o catálogo',
          onClick: () => shareCatalog(),
        },
      ],
    },
    {
      label: 'Baixar PDF',
      href: state.catalogData.pdf,
      target: '_blank',
      download: true,
      onClick: () => analyticsEvent('baixar-catalogo-completo'),
    },
  ];

  const menuDesktop = [
    {
      label: 'Menu acessibilidade',
      onClick: goToAcessibilidade,
    },
    {
      label: 'Compartilhar',
      onClick: () => shareCatalog(),
    },
    {
      label: 'Baixar o catálogo completo',
      href: state.catalogData.pdf,
      target: '_blank',
      download: true,
      onClick: () => analyticsEvent('baixar-catalogo-completo'),
    },
  ];

  return (
    <Styled.MoreButton>
      <DropDownMenu
        position="rb"
        items={state.isDesktop ? menuDesktop : menuMobile}
        eventAnalytics={{
          eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual`,
          eventAction: 'clique',
          eventLabel: 'menu-3-pontos:',
        }}
      >
        <Styled.MoreButtonItem
          aria-label="Menu de opções. Clique para acessar as opções"
          isDesktop={state.isDesktop}
        >
          <MoreVertIcon />
        </Styled.MoreButtonItem>
      </DropDownMenu>

      <DrawerShared
        url={urlShared}
        isOpen={isDrawerShare}
        onOpenChange={closeDrawerShare}
      />
    </Styled.MoreButton>
  );
};
