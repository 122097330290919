import React, { useCallback } from 'react';

import { styled, Text } from '@grupoboticario/flora-react';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import content from 'src/common/content';

import sendShareEvent from 'src/services/googleAnalytics/helpers/sendShareEvent';
import { copyToClipboard } from 'src/common/helpers/copyToClipboard';

interface ISharedButtons {
  url?: string;
  testId?: string;
}

const Styled = {
  OptionsList: styled('ul', {
    display: 'flex',
    justifyContent: 'space-around',
    listStyle: 'none',

    '@tablet': {
      justifyContent: 'space-between',

      li: {
        display: 'inline-flex',
        justifyContent: 'space-around',
      },
    },
  }),

  OptionButton: styled('a', {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    width: '80px',
  }),

  IconWrapper: styled('div', {
    alignItems: 'center',
    borderRadius: '$pill',
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    margin: '0 auto',
    width: '48px',

    '&.whatsapp': {
      background: '$whatsapp',
    },

    '&.facebook': {
      background: '$facebook',
    },

    '&.copyLink': {
      background: '$actionNavigationStandardStatic',
    },

    svg: {
      height: '24px',
      width: 'auto',
      path: {
        fill: '$light-light',
      },
    },
  }),

  OptionLabel: styled(Text, {
    color: '$dark-light',
    display: 'block',
    paddingTop: '$2',
  }),
};

const SharedButtons: React.FC<ISharedButtons> = ({
  url = '',
  testId = 'SharedButtons',
}) => {
  const urlBase = `${content.urlBase}/${url}${url.includes('?') ? '&' : '?'}`;

  const message = useCallback(
    (utmSource: 'wpp' | 'fb') => {
      return encodeURIComponent(
        `Acesse aqui ${content.platform} Digital de ${content.name}: \n ${urlBase}utm_source=${utmSource} \n\n É prático e completo.`,
      );
    },
    [urlBase],
  );

  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${urlBase}utm_source=fb&quote=${message(
    'fb',
  )}`;

  const whatsappLink = `https://api.whatsapp.com/send?text=${message('wpp')}`;

  const handleCopyLinkClick = useCallback(() => {
    sendShareEvent.copyLink();
    copyToClipboard(`${urlBase}utm_source=link`, 'Link copiado com sucesso!');
  }, [urlBase]);

  return (
    <Styled.OptionsList>
      <li>
        <Styled.OptionButton
          href={whatsappLink}
          aria-label="Compartilhar no WhatsApp"
          target="_blank"
          rel="noreferrer noopener"
          data-testid={`${testId}-shareWhatsapp-link`}
          onClick={sendShareEvent.whatsapp}
        >
          <Styled.IconWrapper className="whatsapp">
            <WhatsAppIcon aria-hidden="true" />
          </Styled.IconWrapper>
          <Styled.OptionLabel size="action">WhatsApp</Styled.OptionLabel>
        </Styled.OptionButton>
      </li>
      <li>
        <Styled.OptionButton
          href={facebookLink}
          aria-label="Compartilhar no Facebook"
          target="_blank"
          rel="noreferrer noopener"
          data-testid={`${testId}-shareFacebook-link`}
          onClick={sendShareEvent.facebook}
        >
          <Styled.IconWrapper className="facebook">
            <FacebookIcon aria-hidden="true" />
          </Styled.IconWrapper>
          <Styled.OptionLabel size="action">Facebook</Styled.OptionLabel>
        </Styled.OptionButton>
      </li>
      <li>
        <Styled.OptionButton
          as="button"
          aria-label="Copiar link"
          onClick={handleCopyLinkClick}
          data-testid={`${testId}-copyLink-button`}
        >
          <Styled.IconWrapper className="copyLink">
            <FileCopyIcon aria-hidden="true" />
          </Styled.IconWrapper>
          <Styled.OptionLabel size="action">Copiar link</Styled.OptionLabel>
        </Styled.OptionButton>
      </li>
    </Styled.OptionsList>
  );
};

export default SharedButtons;
