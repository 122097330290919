import { styled } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('div', {
    position: 'relative',

    ul: {
      alignItems: 'center',
      display: 'flex',
      height: '40px',
      padding: '$0',
    },

    '@tablet': {
      ul: {
        li: {
          marginRight: '$8',

          'a, button': {
            span: {
              display: 'block',
            },
          },
        },
      },
    },
  }),

  HeaderListItem: styled('li', {
    listStyle: 'none',
    marginRight: '$4',
    'a, button': {
      alignItems: 'center',
      background: 'transparent',
      border: 'none',
      color: '$brand-1',
      display: 'flex',
      flexDirection: 'row',
      textDecoration: 'none',
      textTransform: 'uppercase',
      svg: {
        height: '14px',
        width: 'auto',

        path: {
          fill: '$brand-1',
        },
      },

      span: {
        marginLeft: '$2',
      },
    },

    variants: {
      desktopOnly: {
        true: {
          display: 'none',

          '@tablet': {
            display: 'block',
          },
        },
      },
    },
  }),
};
