import content from 'src/common/content';
import { injectUTMParamsInUrl } from '../injectUTMParamsInUrl/injectUTMParamsInUrl';

export type IGetCatalogUrlParams = {
  uf: string;
};

export const getCatalogUrl = ({ uf }: IGetCatalogUrlParams) => {
  const url =
    process.env.BRAND_IDENTIFIER.toString() === 'eudora'
      ? `https://minhaloja.eudora.com.br/guiadigital?uf=${uf.toUpperCase()}`
      : `https://minhaloja.boticario.com.br/catalogodigital?uf=${uf.toUpperCase()}`;

  return injectUTMParamsInUrl(url, content.CatalogUTMParams);
};
