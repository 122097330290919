import { ICatalogState } from './store/types';
import { getValidPagePosition } from './store/services';
import { getPageFromUrl } from 'src/common/helpers';


export const getInitialPosition = (
  defaultInitialPosition: ICatalogState['page'],
  numberOfPages: ICatalogState['numberOfPages'],
) => {
  const pagePositionFromURL = getPageFromUrl();

  return getValidPagePosition(
    defaultInitialPosition,
    pagePositionFromURL,
    numberOfPages,
  );
};

export const reducerInitializer =
  (catalogData: ICatalogState['catalogData']) =>
  (initialState: ICatalogState): ICatalogState => {
    const numberOfPages = catalogData.pages.length;

    return {
      ...initialState,
      page: getInitialPosition(initialState.page, numberOfPages),
      uf: catalogData.cycle.state,
      numberOfPages,
      catalogData,
    };
  };
