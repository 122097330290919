import { IContent } from './types';
import boticario from './boticario';
import eudora from './eudora';
import boticario_reseller from './boticario_reseller';
import shared from './shared';

export const content: {
  boticario: IContent;
  eudora: IContent;
  boticario_reseller: IContent;
} = {
  boticario,
  eudora,
  boticario_reseller,
};

export default { ...content[process.env.BRAND_IDENTIFIER], ...shared };
