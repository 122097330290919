export const injectUTMParamsInUrl = (
  originalURL: string,
  { source, medium }: { source: string; medium: string },
) => {
  try {
    let url = new URL(originalURL);
    url.searchParams.append('utm_source', source);
    url.searchParams.append('utm_medium', medium);
    return url.toString();
  } catch (err) {
    /**
     * In order to keep old browsers compatibility
     * that may not have URL and URLSearchParams api
     */
    const paramsConector = originalURL.includes('?') ? '&' : '?';
    return `${originalURL}${paramsConector}utm_source=${source}&utm_medium=${medium}`;
  }
};
