import {useRouter} from 'next/router';
import { useContext, useEffect } from 'react';
import { CatalogContext } from '../CatalogContext';

export const useUpdateURLPageParam = () => {
  const router = useRouter();
  const [catalogState] = useContext(CatalogContext);

  useEffect(() => {
    router.replace({
      pathname: `/${catalogState.uf?.toLocaleLowerCase()}/visualizar`,
      query: {
        page: catalogState.page,
      }
    });
  }, [catalogState.page])
};
