import { styled } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('div', {
    minWidth: 'none',
    position: 'relative',
    width: '100%',

    label: {
      color: '$dark-light',
    },

    input: {
      paddingRight: '54px',
    },

    '@tablet': {
      minWidth: '300px',
    },
  }),

  Button: styled('div', {
    position: 'absolute',
    right: '1px',
    top: '50%',
    transform: 'translateY(-50%)',
    button: {
      height: '46px',
      width: '46px',
    },
  }),
};
