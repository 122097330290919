import { styled } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('div', {
    padding: '$0',
    textAlign: 'center',
  }),

  Form: styled('div', {
    display: 'none',
    width: '100%',

    '@tablet': {
      display: 'block',
    },
  }),

  ButtonSearch: styled('div', {
    display: 'block',

    a: {
      color: '$light-light',
      svg: {
        display: 'block',
      },
    },

    '@tablet': {
      display: 'none',
    },
  }),
};
