import localStorageService from 'src/services/localStorage/localStorageService';
import { v4 as uuidv4 } from 'uuid';

const getUserId = (): string => {
  const userId = localStorageService.getItem('uuid') || uuidv4();
  localStorageService.setItem('uuid', userId);

  return userId;
};

export default getUserId;
