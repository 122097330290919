import React, { useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { Text } from '@grupoboticario/flora-react';

import { useHotkeys } from 'react-hotkeys-hook';

import { useStyle } from 'src/contexts/StyleContext';

import RevistaIcons from 'src/assets/icons';

import sendGoogleAnalytics from 'src/services/googleAnalytics/sendGoogleAnalytics';
import { handleHotKeysEvent } from 'src/common/helpers/analyticsEvents';

import { Container } from 'src/styles';

import Styled from './HeaderAccessibilityStyle';

export default function HeaderAccessibility(): JSX.Element {
  const testId = 'header-accessibility';

  const router = useRouter();

  const { style, toggleContrast, toggleFont, getStyle } = useStyle();

  const handleAccessibilityClick = useCallback((eventLabel: string) => {
    sendGoogleAnalytics.event({
      eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`,
      eventAction: 'clique:header-acessibilidade',
      eventLabel,
    });
  }, []);

  const handleToggleFont = () => {
    toggleFont();
    const eventLabel = `${style.fontLarge ? 'diminuir' : 'aumentar'}-fonte`;
    handleAccessibilityClick(eventLabel);
  };

  const handleToggleContrast = () => {
    toggleContrast();
    const eventLabel = `${style.contrast ? 'diminuir' : 'aumentar'}-contraste`;
    handleAccessibilityClick(eventLabel);
  };

  useHotkeys('Alt+1', () => {
    handleHotKeysEvent('1');
    router.push('/acessibilidade');
  });

  useHotkeys('Alt+2', () => {
    handleHotKeysEvent('2');
    const catalogInfo = document.getElementById('catalog-info');
    if (catalogInfo) {
      window.location.hash = '#catalog-info';
      catalogInfo.focus();
    } else {
      router.push('/');
    }
  });

  useEffect(() => {
    getStyle();
  }, []);

  return (
    <Styled.Wrapper data-testid={testId}>
      <Container>
        <ul>
          <Styled.HeaderListItem
            data-testid={`${testId}-instructions`}
            onClick={() => handleAccessibilityClick('acessibilidade')}
          >
            <Link href="/acessibilidade">
              <a aria-label="Opções de acessibilidade">
                <RevistaIcons name="accessibility" aria-hidden="true" />
                <Text size="action">Opções de acessibilidade</Text>
              </a>
            </Link>
          </Styled.HeaderListItem>

          <Styled.HeaderListItem desktopOnly>
            <button
              onClick={handleToggleFont}
              data-testid={`${testId}-font`}
              title={`${style.fontLarge ? 'Diminuir' : 'Aumentar'} fonte`}
            >
              {style.fontLarge ? (
                <RevistaIcons name="decreaseFont" />
              ) : (
                <RevistaIcons name="increaseFont" />
              )}
              <Text size="action">
                {style.fontLarge ? 'Diminuir' : 'Aumentar'} fonte
              </Text>
            </button>
          </Styled.HeaderListItem>

          <Styled.HeaderListItem desktopOnly>
            <button
              onClick={handleToggleContrast}
              data-testid={`${testId}-contrast`}
              title={`${style.contrast ? 'Diminuir' : 'Aumentar'} contraste`}
            >
              <RevistaIcons name="contrast" />
              <Text size="action">
                {style.contrast ? 'Diminuir' : 'Aumentar'} contraste
              </Text>
            </button>
          </Styled.HeaderListItem>
        </ul>
      </Container>
    </Styled.Wrapper>
  );
}
