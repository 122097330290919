import { getUserId } from 'src/common/helpers';

declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}

export interface GAEventInterface {
  dimension4?: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue?: number;
}

interface SendGoogleAnalyticsInterface {
  pageView: (state: string) => void;
  event: (dataLayer: GAEventInterface) => void;
}

const sendGoogleAnalytics: SendGoogleAnalyticsInterface = {
  pageView: (state: string): void => {
    const userId = getUserId();
    const dataLayer: { [key: string]: string } = {
      dimension1: userId,
    };
    state && (dataLayer['dimension4'] = state);

    window.dataLayer.push(dataLayer);
  },

  event: (dataLayer: GAEventInterface): void => {
    window.dataLayer.push({
      ...dataLayer,
      event: 'event',
    });
  },
};

export default sendGoogleAnalytics;
