import React, { FC } from 'react';
import Link from 'next/link';

import { Text } from '@grupoboticario/flora-react';

import content from 'src/common/content';

import { ICatalog } from 'src/common/types/CatalogType';

import useScrollY from 'src/common/hooks/useScrollY';

import { Container } from 'src/styles';
import Styled from './HeaderStyled';

import HeaderAccessibility from 'src/components/molecules/HeaderAccessibility';
import HeaderActionSearch from 'src/components/molecules/HeaderActionSearch';
import { CatalogMenu } from '../CatalogMenu';

interface IHeaderProps {
  isCatalogViewHeader?: boolean;
  catalog?: ICatalog;
}

const Logo: FC = () => (
  <Styled.Logo title={`${content.platform} digital de ${content.name}`}>
    <img src={content.header.logo.image} alt={content.name} aria-hidden />
    <Text as="h1" size="bodyMediumShort" color="$light-light">
      {content.platform} Digital
    </Text>
  </Styled.Logo>
);

export default function Header({
  isCatalogViewHeader = false,
  catalog,
}: IHeaderProps): JSX.Element {
  const testId = 'header';
  const scroll = useScrollY();
  const landingPage = catalog ? `/${catalog.cycle.state.toLowerCase()}` : '/';

  return (
    <>
      {!isCatalogViewHeader && <HeaderAccessibility />}
      {scroll > 40 && <Styled.Wrapper />}
      <Styled.Wrapper data-testid={testId} fixed={scroll > 40}>
        <Container>
          <Styled.WrapperRow>
            {isCatalogViewHeader ? (
              <Link href={landingPage}>
                <a>
                  <Logo />
                </a>
              </Link>
            ) : (
              <Logo />
            )}
            {!!isCatalogViewHeader && (
              <Styled.Options>
                <HeaderActionSearch />
                <Styled.MenuOptions>
                  <CatalogMenu />
                </Styled.MenuOptions>
              </Styled.Options>
            )}
          </Styled.WrapperRow>
        </Container>
      </Styled.Wrapper>
    </>
  );
}
