import sendGoogleAnalytics from 'src/services/googleAnalytics/sendGoogleAnalytics';

export const handleDownloadPdfClick = (): void => {
  sendGoogleAnalytics.event({
    eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`,
    eventAction: 'clique:botao-compartilhar',
    eventLabel: 'baixar-pdf',
  });
};

export const handleAccessibilityMenuClick = (eventLabel: string): void => {
  sendGoogleAnalytics.event({
    eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`,
    eventAction: 'clique:menu-acessibilidade',
    eventLabel,
  });
};

export const handleHotKeysEvent = (eventLabel: string): void => {
  sendGoogleAnalytics.event({
    eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`,
    eventAction: 'tecla:alt',
    eventLabel,
  });
};

