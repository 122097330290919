import { IContent } from 'src/common/content/types';
import { stateUf } from 'src/common/helpers';

const boticario: IContent = {
  name: 'O Boticário',
  platform: 'Catálogo',
  urlBase: 'https://catalogo.boticario.com.br',
  defaultState: 'SP',
  ogImage:
    'https://meucatalogodigitalresources.grupoboticario.digital/assets/images/og-image.jpg',
  optimizeId: 'OPT-WXLHS7T',
  CatalogUTMParams: {
    source: 'site_catalogo_boticario',
    medium: 'botao_acessar_catalogo',
  },
  header: {
    logo: {
      image:
        'https://gb-assets.grupoboticario.digital/boticario/logo/logo-white.svg',
      alt: 'Marca O Boticário',
    },
  },
  footer: {
    social: [
      {
        name: 'facebook',
        icon: 'facebook',
        url: 'https://facebook.com/oboticario',
      },
      {
        name: 'instagram',
        icon: 'instagram',
        url: 'https://instagram.com/oboticario',
      },
      {
        name: 'youtube',
        icon: 'youtube',
        url: 'https://youtube.com/user/boticario',
      },
      {
        name: 'twitter',
        icon: 'twitter',
        url: 'https://twitter.com/oboticario',
      },
    ],
    navigation: [
      {
        label: 'Quero ser um revendedor',
        url: 'https://revenda.boticario.com.br/?utm_source=catalogo&utm_medium=footer',
      },
      {
        label: 'Loja on-line O Boticário',
        url: 'https://www.boticario.com.br/?utm_source=catalogo&utm_medium=footer',
      },
    ],
    logo: {
      image:
        'https://gb-assets.grupoboticario.digital/boticario/image/anfora-white.png',
      alt: 'Marca O Boticário',
    },
  },
  states: [
    'AC',
    'AL',
    'AM',
    'AP',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MG',
    'MS',
    'MT',
    'PA',
    'PB',
    'PE',
    'PI',
    'PR',
    'RJ',
    'RN',
    'RO',
    'RR',
    'RS',
    'SC',
    'SE',
    'SP',
    'TO',
  ],
  // @ts-ignore
  getUFByStateSlug: (slug: string, city?: string) => stateUf[slug],
  summary: {
    title: 'Você vai encontrar nesse catálogo:',
    categories: [
      {
        image: 'perfumaria',
        label: 'Novidades em perfumaria',
      },
      {
        image: 'maquiagem',
        label: 'Cuidados e maquiagem',
      },
      {
        image: 'promocoes',
        label: 'Promoções',
      },
    ],
  },
  products: {
    showPrice: true,
    findReseller: {
      button: {
        ariaLabel: 'Encontre uma revendedora ou um revendedor',
        label: 'Encontre um(a) revendedor(a)',
        url: 'https://encontre.boticario.com.br/?utm_source=revista-digital&utm_medium=detalhes-do-produto&utm_campaign=',
      },
    },
  },
};

export default boticario;
