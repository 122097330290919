// Modules
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import slugify from 'react-slugify';

import { AppProps } from 'next/app';
import Head from 'next/head';

import StyleContext from 'src/contexts/StyleContext';
import LastSearchContext from 'src/contexts/LastSearchContext';

import 'react-toastify/dist/ReactToastify.css';

import content from 'src/common/content';

import { getUserId, stateName } from 'src/common/helpers';

import sendGoogleAnalytics from 'src/services/googleAnalytics/sendGoogleAnalytics';

import { ErrorBoundary } from 'src/components/organisms/ErrorBoundary';

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    sendGoogleAnalytics.pageView(slugify(stateName[pageProps.state]));
  }, [pageProps]);

  useEffect(() => {
    const userId = getUserId();

    if (typeof window.newrelic === 'object') {
      window.newrelic.setCustomAttribute('userId', userId);
    }

    if (typeof window.hj === 'function') {
      window.hj('identify', userId, {
        user_id: userId,
      });
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{`${content.platform} Digital - ${content.name}`}</title>
        <meta property="og:image" name="og:image" content={content.ogImage} />
      </Head>

      <StyleContext.Provider>
        <ErrorBoundary>
          <LastSearchContext.Provider>
            <Component {...pageProps} />
          </LastSearchContext.Provider>
          <ToastContainer />
        </ErrorBoundary>
      </StyleContext.Provider>
    </>
  );
};

export default MyApp;
