import { useEffect, useState } from 'react';
import { debounce } from 'src/common/helpers/functional';

interface WindowSize {
  width: number;
  height: number;
  isDesktop: boolean;
}

const MOBILE_SIZE = 767;
const DEBOUNCE_TIMEOUT = 100;

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    isDesktop: false,
  });

  useEffect(() => {
    const handler = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isDesktop: window.innerWidth > MOBILE_SIZE,
      });
    };
    handler();

    const debouncedHandler = debounce(handler, DEBOUNCE_TIMEOUT);
    window.addEventListener('resize', debouncedHandler);

    return () => {
      window.removeEventListener('resize', debouncedHandler);
    };
  }, []);

  return windowSize;
}

export default useWindowSize;
