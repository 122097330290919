import React, {
  createContext,
  useContext,
  Context,
  useState,
  useEffect,
} from 'react';
import localStorageService from 'src/services/localStorage/localStorageService';

const defaultValue = { lastSearchIdentifier: null, scrollPosition: 0 };

const LastSearchContext: Context<any> = createContext({});

const LastSearchProvider: React.FC<{
  value?: {
    lastSearchIdentifier: null | string;
    scrollPosition: number;
  };
}> = ({ value = defaultValue, children }) => {
  const [lastSearch, setLastSearch] = useState(value);

  useEffect(() => {
    const data = localStorageService.getItem('lastSearch');
    setLastSearch(data ? JSON.parse(data) : lastSearch);
  }, []);

  const clearLastSearch = (): void => {
    setLastSearch(defaultValue);
    localStorageService.setItem('lastSearch', JSON.stringify(defaultValue));
  };

  const updateLastSearch = (
    searchIdentifier: string,
    scrollPosition: number,
  ): void => {
    const newValue = { lastSearchIdentifier: searchIdentifier, scrollPosition };
    setLastSearch(newValue);

    localStorageService.setItem('lastSearch', JSON.stringify(newValue));
  };

  return (
    <LastSearchContext.Provider
      value={{
        lastSearch,
        clearLastSearch,
        updateLastSearch,
      }}
    >
      {children}
    </LastSearchContext.Provider>
  );
};

export const useLastSearch = () => useContext(LastSearchContext);

export default {
  ...LastSearchContext,
  Provider: LastSearchProvider,
};
