import { ICatalog } from 'src/common/types/CatalogType';

import { ICatalogState, CatalogActions, CatalogActionTypes } from './types';

import {
  getExhibitionData,
  getValidPagePosition,
  getNextPagePosition,
  getPreviousPagePosition,
} from './services';

export const INITIAL_CATALOG_STATE: ICatalogState = {
  page: 1,
  numberOfPages: 1,
  isZoomActive: false,
  catalogData: {} as ICatalog,
  isDesktop: false,
  exhibition: {
    previousPagesData: [],
    currentPagesData: [],
    nextPagesData: [],
  },
};

export const catalogReducer = (
  state: ICatalogState,
  action: CatalogActions,
): ICatalogState => {
  const CURRENT_POSITION = state.page;
  const NUMBER_OF_PAGES = state.numberOfPages;
  const PAGES = state.catalogData.pages;

  switch (action.type) {
    case CatalogActionTypes.GO_TO_NEXT_PAGE: {
      const NEXT_POSITION = getNextPagePosition(
        CURRENT_POSITION,
        state.isDesktop,
      );

      const nextPage = getValidPagePosition(
        CURRENT_POSITION,
        NEXT_POSITION,
        NUMBER_OF_PAGES,
      );

      return {
        ...state,
        page: nextPage,
        exhibition: getExhibitionData(nextPage, PAGES, state.isDesktop),
      };
    }

    case CatalogActionTypes.GO_TO_PREVIOUS_PAGE: {
      const PREVIOUS_POSITION = getPreviousPagePosition(
        CURRENT_POSITION,
        state.isDesktop,
      );

      const previousPage = getValidPagePosition(
        CURRENT_POSITION,
        PREVIOUS_POSITION,
        NUMBER_OF_PAGES,
      );

      return {
        ...state,
        page: previousPage,
        exhibition: getExhibitionData(previousPage, PAGES, state.isDesktop),
      };
    }

    case CatalogActionTypes.GO_TO_PAGE: {
      const newPage = getValidPagePosition(
        CURRENT_POSITION,
        action.payload,
        NUMBER_OF_PAGES,
      );

      return {
        ...state,
        page: newPage,
        exhibition: getExhibitionData(newPage, PAGES, state.isDesktop),
      };
    }

    case CatalogActionTypes.ZOOM_IN: {
      return {
        ...state,
        isZoomActive: true,
      };
    }

    case CatalogActionTypes.ZOOM_OUT: {
      return {
        ...state,
        isZoomActive: false,
      };
    }

    case CatalogActionTypes.IDENTIFY_DEVICE_TYPE: {
      return {
        ...state,
        isDesktop: action.payload,
        exhibition: getExhibitionData(CURRENT_POSITION, PAGES, action.payload),
      };
    }

    default:
      return { ...state };
  }
};
