interface StateNameInterface {
  [key: string]: string;
}

const stateName: StateNameInterface = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SPC: 'São Paulo Capital',
  SPI: 'São Paulo Interior',
  SE: 'Sergipe',
  TO: 'Tocantins',
  DF: 'Distrito Federal',
};

const stateUf: StateNameInterface = {
  acre: 'ac',
  alagoas: 'al',
  amapa: 'ap',
  amazonas: 'am',
  bahia: 'ba',
  ceara: 'ce',
  'espirito-santo': 'es',
  goias: 'go',
  maranhao: 'ma',
  'mato-grosso': 'mt',
  'mato-grosso-do-sul': 'ms',
  'minas-gerais': 'mg',
  para: 'pa',
  paraiba: 'pb',
  parana: 'pr',
  pernambuco: 'pe',
  piaui: 'pi',
  'rio-de-janeiro': 'rj',
  'rio-grande-do-norte': 'rn',
  'rio-grande-do-sul': 'rs',
  rondonia: 'ro',
  roraima: 'rr',
  'santa-catarina': 'sc',
  'sao-paulo': 'sp',
  'sao-paulo-capital': 'spc',
  'sao-paulo-interior': 'spi',
  sergipe: 'se',
  tocantins: 'to',
  'distrito-federal': 'df',
};

export { stateName, stateUf };
