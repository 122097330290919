import sendGoogleAnalytics from '../../sendGoogleAnalytics';

const GAShareEvent = (eventLabel: string) => {
  sendGoogleAnalytics.event({
    eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`,
    eventAction: 'clique:botao-compartilhar',
    eventLabel,
  });
};

const sendShareEvent = {
  whatsapp: () => {
    GAShareEvent('whatsapp');
  },

  facebook: () => {
    GAShareEvent('facebook');
  },

  copyLink: () => {
    GAShareEvent('copiar-link');
  },
};

export default sendShareEvent;
