import slugify from 'react-slugify';

const eudoraSpcCities = [
  'aruja',
  'barueri',
  'bertioga',
  'biritiba-mirim',
  'cacapava',
  'carapicuiba',
  'cotia',
  'cubatao',
  'diadema',
  'embu-das-artes',
  'embu-guacu',
  'ferraz-de-vasconcelos',
  'guararema',
  'guaruja',
  'guarulhos',
  'igarata',
  'itanhaem',
  'itapecerica-da-serra',
  'itapevi',
  'itaquaquecetuba',
  'jacarei',
  'jambeiro',
  'jandira',
  'juquitiba',
  'maua',
  'mogi-das-cruzes',
  'mongagua',
  'osasco',
  'paraibuna',
  'peruibe',
  'poa',
  'praia-grande',
  'ribeirao-pires',
  'rio-grande-da-serra',
  'salesopolis',
  'santa-branca',
  'santa-isabel',
  'santo-andre',
  'santos',
  'sao-bernardo-do-campo',
  'sao-caetano-do-sul',
  'sao-jose-dos-campos',
  'sao-lourenco-da-serra',
  'sao-paulo',
  'sao-vicente',
  'suzano',
  'taboao-da-serra',
  'taubate',
  'tremembe',
  'vargem-grande-paulista',
];

export const isEudoraSpc = (city: string): boolean =>
  eudoraSpcCities.includes(slugify(city));
