import { styled } from '@grupoboticario/flora-react';

const Container = styled('div', {
  margin: '$0 auto',
  padding: '$0 $4',
  maxWidth: '1170px',
  width: '100%',
});

export default Container;
