import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@grupoboticario/flora-react';

import sendGoogleAnalytics, {
  GAEventInterface,
} from 'src/services/googleAnalytics/sendGoogleAnalytics';

import { IListMenuItem, ListMenu } from 'src/components/atoms/ListMenu';

const Styled = {
  Wrapper: styled('div', {
    display: 'inline-block',
    position: 'relative',
  }),

  Action: styled('div', {
    display: 'inline-block',
  }),

  Content: styled('div', {
    position: 'absolute',
    left: 0,
    top: '100%',
    zIndex: 9,

    variants: {
      position: {
        lt: {
          bottom: '100%',
          left: 0,
          top: 'auto',
        },
        lb: {
          left: 0,
          top: '100%',
        },
        rt: {
          bottom: '100%',
          left: 'auto',
          right: '0',
          top: 'auto',
        },
        rb: {
          left: 'auto',
          right: '0',
          top: '100%',
        },
      },
    },
  }),
};

interface IDropDownMenu {
  position?: 'lt' | 'lb' | 'rt' | 'rb';
  items: IListMenuItem[];
  eventAnalytics: GAEventInterface;
}

export const DropDownMenu: React.FC<IDropDownMenu> = ({
  children,
  items,
  position = 'lb',
  eventAnalytics,
}) => {
  const wrapperRef = useRef(null);

  const [menu, setMenu] = useState(items);
  const [show, setShow] = useState(false);

  const handleAnalytics = (label: string) => {
    sendGoogleAnalytics.event({
      ...eventAnalytics,
      eventLabel: label,
    });
  };

  const toogleShow = () => {
    setMenu(items);
    setShow(!show);

    const labelAnalytics = show ? 'fechar' : 'abrir';
    handleAnalytics(eventAnalytics.eventLabel + labelAnalytics);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        // @ts-ignore
        !wrapperRef.current.contains(event.target) &&
        show
      ) {
        setMenu(items);
        setShow(false);
        handleAnalytics(eventAnalytics.eventLabel + 'fechar');
      }
    }

    setMenu(items);

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, items, show]);

  return (
    <Styled.Wrapper ref={wrapperRef} data-testid="menudropdown">
      <Styled.Action onClick={toogleShow} data-testid="menudropdown-action">
        {children}
      </Styled.Action>
      {!!show && (
        <Styled.Content position={position} data-testid="menudropdown-content">
          <ListMenu items={menu} onChangeMenu={setMenu} />
        </Styled.Content>
      )}
    </Styled.Wrapper>
  );
};
