import { ICatalog, IPage } from 'src/common/types/CatalogType';

/**
 * ActionTypes
 */
export enum CatalogActionTypes {
  GO_TO_NEXT_PAGE = '@catalog/GO_TO_NEXT_PAGE',
  GO_TO_PREVIOUS_PAGE = '@catalog/GO_TO_PREVIOUS_PAGE',
  GO_TO_PAGE = '@catalog/GO_TO_PAGE',

  ZOOM_IN = '@catalog/ZOOM_IN',
  ZOOM_OUT = '@catalog/ZOOM_OUT',

  IDENTIFY_DEVICE_TYPE = '@catalog/IDENTIFY_DEVICE_TYPE',
}

/**
 * Action Creators
 */
export interface IGoToNextPageAction {
  type: CatalogActionTypes.GO_TO_NEXT_PAGE;
}
export interface IGoToPreviousPageAction {
  type: CatalogActionTypes.GO_TO_PREVIOUS_PAGE;
}
export interface IGoToPageAction {
  type: CatalogActionTypes.GO_TO_PAGE;
  payload: number;
}

export interface IZoomInAction {
  type: CatalogActionTypes.ZOOM_IN;
}
export interface IZoomOutAction {
  type: CatalogActionTypes.ZOOM_OUT;
}

export interface IIdentifyDeviceTypeAction {
  type: CatalogActionTypes.IDENTIFY_DEVICE_TYPE;
  payload: boolean;
}

export type CatalogActions =
  | IGoToNextPageAction
  | IGoToPreviousPageAction
  | IGoToPageAction
  | IZoomInAction
  | IZoomOutAction
  | IIdentifyDeviceTypeAction;

/**
 * State
 */
export interface ICatalogState {
  page: number;
  numberOfPages: number;
  isZoomActive: boolean;
  catalogData: ICatalog;
  uf?: string;
  isDesktop?: boolean;
  exhibition: {
    currentPagesData: IPage[];
    previousPagesData: IPage[];
    nextPagesData: IPage[];
  };
}
