import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import SearchIcon from '@material-ui/icons/Search';

import Styled from './HeaderActionSearchStyle';
import SearchForm, { ISearchForm } from '../SearchForm';

import sendClickEvent from 'src/services/googleAnalytics/helpers/sendClickEvent';

const HeaderActionSearch = () => {
  const router = useRouter();

  const onSubmit: ISearchForm['onSubmit'] = (data) => {
    router.push({
      pathname: '/busca',
      query: {
        uf: router.query.uf,
        term: data.term,
      },
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.Form>
        <SearchForm onSubmit={onSubmit} />
      </Styled.Form>

      <Styled.ButtonSearch
        onClick={sendClickEvent.search}
        data-testid="HeaderActionSearch-link"
      >
        <Link
          href={`/busca${router.query.uf ? `/?uf=${router.query.uf}` : ''}`}
        >
          <a
            aria-label="Ir para busca"
            data-testid="HeaderActionSearch-goToSearch-button"
          >
            <SearchIcon fontSize="large" />
          </a>
        </Link>
      </Styled.ButtonSearch>
    </Styled.Wrapper>
  );
};

export default HeaderActionSearch;
