import React, { FC } from 'react';

import { ErrorTypes } from 'src/common/types/ErrorTypes';

import Header from 'src/components/organisms/Header';
import Footer from 'src/components/organisms/Footer';
import { ErrorFeedback } from 'src/components/molecules/ErrorFeedback';

interface ErrorPageProps {
  type: ErrorTypes;
}

export const ErrorPage: FC<ErrorPageProps> = ({
  type = ErrorTypes.default,
}) => {
  return (
    <>
      <Header />
      <ErrorFeedback type={type} />
      <Footer />
    </>
  );
};
