import React, {
  createContext,
  useContext,
  Context,
  useState,
  useEffect,
} from 'react';

import {
  boticarioTheme,
  eudoraLightTheme,
  highContrastTheme,
} from '@grupoboticario/flora';
import { FloraProvider } from '@grupoboticario/flora-react';

import { GlobalStyle } from 'src/styles/Global';
import localStorageService from 'src/services/localStorage/localStorageService';

const LS = 'style';

const isSSGEnv = typeof window === 'undefined';

interface IStyle {
  contrast: boolean;
  fontLarge: boolean;
  fullScreen: boolean;
  isProductDrawerOpen?: boolean;
}

const defaultValue = {
  contrast: false,
  fontLarge: false,
  fullScreen: false,
  isProductDrawerOpen: false,
};

const StyleContext: Context<any> = createContext({});

const StyleProvider: React.FC<{
  value?: IStyle;
}> = ({ value = defaultValue, children }) => {
  const [style, setStyle] = useState(value);

  const getStyle = (): void => {
    const data = localStorageService.getItem(LS);
    setStyle(data ? JSON.parse(data) : style);
  };

  const setAll = (data: IStyle) => {
    setStyle(data);
    localStorageService.setItem(LS, JSON.stringify(data));
  };

  const toggleContrast = (): void => {
    const data = { ...style, contrast: !style.contrast };
    setAll(data);
  };

  const toggleFont = (): void => {
    const data = { ...style, fontLarge: !style.fontLarge };
    setAll(data);
  };

  const toggleFullScreen = (): void => {
    const data = { ...style, fullScreen: !style.fullScreen };
    setAll(data);
  };

  const setIsProductDrawerOpen = (isProductDrawerOpen: boolean): void => {
    const data = { ...style, isProductDrawerOpen: isProductDrawerOpen };
    setAll(data);
  };

  const floraTheme = {
    boticario: boticarioTheme,
    eudora: eudoraLightTheme,
    boticario_reseller: boticarioTheme,
    contrast: highContrastTheme,
  };

  const theme =
    floraTheme[!!style.contrast ? 'contrast' : process.env.BRAND_IDENTIFIER] ||
    '';

  GlobalStyle();

  useEffect(() => {
    const fontSize = style.fontLarge ? '20px' : '16px';

    document.getElementsByTagName('html')[0].style.fontSize = fontSize;
    document.getElementsByTagName('body')[0].style.fontSize = fontSize;
  }, [style.fontLarge]);

  React.useLayoutEffect(() => {
    if (!isSSGEnv) {
      document.body.classList.add(theme);
    }
  });

  return (
    <StyleContext.Provider
      value={{
        style,
        getStyle,
        toggleFont,
        toggleContrast,
        toggleFullScreen,
        setIsProductDrawerOpen,
        ...value,
      }}
    >
      <FloraProvider>
        <div className={`main-theme ${theme}`} data-testid="main-theme">
          {children}
        </div>
      </FloraProvider>
    </StyleContext.Provider>
  );
};
export const useStyle = () => useContext(StyleContext);

export default {
  ...StyleContext,
  Provider: StyleProvider,
};
