import sendGoogleAnalytics from '../../sendGoogleAnalytics';

const GAButtonClickEvent = (eventLabel: string) => {
  sendGoogleAnalytics.event({
    eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`,
    eventAction: 'clique:botao',
    eventLabel,
  });
};

const GAClickEvent = (eventLabel: string) => {
  sendGoogleAnalytics.event({
    eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual`,
    eventAction: 'clique',
    eventLabel,
  });
};

const sendClickEvent = {
  accessCatalog: () => {
    GAButtonClickEvent('acessar-catalogo');
  },

  downloadPdf: () => {
    GAButtonClickEvent('baixar-pdf');
  },

  goBack: () => {
    GAButtonClickEvent('voltar');
  },

  accessAccessibleCatalog: () => {
    GAButtonClickEvent('acessar-catalogo-acessivel');
  },

  goBackProductDetail: () => {
    GAClickEvent('product-detail:voltar');
  },

  search: () => {
    GAButtonClickEvent('buscar');
  },

  goToAccessibilityOptions: () => {
    GAButtonClickEvent('opcoes-de-acessibilidade');
  },
};

export default sendClickEvent;
