import { globalCss } from '@grupoboticario/flora-react';

export const GlobalStyle = globalCss({
  '*': {
    boxSizing: 'border-box',
    margin: '0',
    padding: '0',
  },

  'html, body': {
    margin: '0',
    padding: '0',
  },

  'a, button, select': {
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
  },

  svg: {
    path: {
      transition: 'all 0.2s ease-in-out',
    },
  },

  '.container': {
    margin: '$0 auto',
    maxWidth: '1170px',
    padding: '$0 $4',
    width: '100%',
  },

  main: {
    background: '$light-dark-1',
    flex: 'auto',
    width: '100%',
  },

  '.main-content': {
    minHeight: 'calc(100vh - 40px - 60px - 135px)',
    padding: '$5',
  },

  '.Toastify__toast--success': {
    background: '$success-standard',
    color: '$light-light',
  },

  '.ProductDescription p': {
    marginBottom: '$2',
  },
});
