import React, {
  FC,
  createContext,
  useReducer,
  useEffect,
  Dispatch,
} from 'react';

import useWindowSize from 'src/common/hooks/useWindowSize';

import { ICatalogState, CatalogActions } from './store/types';
import { identifyDeviceType } from './store/actions';
import { catalogReducer, INITIAL_CATALOG_STATE } from './store/reducer';
import { reducerInitializer } from './CatalogContextServices';

export const CatalogContext = createContext<
  [ICatalogState, Dispatch<CatalogActions>]
>([INITIAL_CATALOG_STATE, () => undefined]);

interface ICatalogProvider {
  catalogData: ICatalogState['catalogData'];
}

export const CatalogProvider: FC<ICatalogProvider> = ({
  catalogData,
  children,
}) => {
  const [state, dispatch] = useReducer(
    catalogReducer,
    INITIAL_CATALOG_STATE,
    reducerInitializer(catalogData),
  );

  const { isDesktop } = useWindowSize();

  useEffect(() => {
    dispatch(identifyDeviceType(isDesktop));
  }, [isDesktop]);

  return (
    <CatalogContext.Provider value={[state, dispatch]}>
      {children}
    </CatalogContext.Provider>
  );
};
